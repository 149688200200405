.title1 {
  font-size: rem(18px);
  color: $t-dark;
  font-weight: $t-semi-bold;
  margin-top: $m2;
  display: block;
}

.title2 {
  @include font(
    $fs: $fs-small,
    $fw: $t-semi-bold,
    $co: $t-dark,
    $lh: rem(22px)
  );
  margin-top: $m1;
  display: block;
}

.text3 {
  @include font(
    $fs: $fs-small,
    $fw: $t-semi-bold,
    $co: $t-light,
    $lh: rem(22px)
  );
}

.text2 {
  @include font($fs: $fs-small, $fw: $t-regular, $co: $t-light, $lh: rem(22px));
}

.ml1 {
  margin-left: $m1;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: $m1;
}

.mt2 {
  margin-top: $m2;
}

.mt3 {
  margin-top: $m3;
}

.mb1 {
  margin-bottom: $m1;
}

.mb2 {
  margin-bottom: $m2;
}

.mb3 {
  margin-bottom: $m3;
}

.mb5 {
  margin-bottom: $m5;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align-last: center;
}

.text-left {
  text-align: left;
}

.d-inline {
  display: inline-block;
}

.text-bold {
  font-weight: $fw-bold;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-left: 5px;
}

.badgeVoluntario {
  color: #fff;
  background-color: #a2322e;
}

.badgeSocio {
  color: #fff;
  background-color: #e49e02;
}

.badgeAcompanamiento {
  color: #fff;
  background-color: #96d0ef;
}
