.grid {
  width: 100%;
  margin: 36px auto 0;
  padding: 0;
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  width: 510px;
  text-align: center;
}
.gridSection {
  margin: 0 auto;
  max-width: 550px;
}
section {
  .title {
    background-color: #e8e9ec;
    padding: 18px 20px;
    width: 100%;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #2b5e77;
    margin-bottom: 30px;
  }
}
.grid .row {
  display: contents;
}

.grid .row label {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: #000;
  text-align: left;
}
.grid .row .val {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: #000;
  text-align: left;
}
.list {
  width: 100%;
  border-spacing: 0 2px;
  border-collapse: separate;

  &.isBox {
    border-spacing: 0 5px;

    tbody:before {
      display: none;
    }
  }

  &.is4Columns {
    td,
    th {
      width: calc(100% / 3);
    }
  }

  &.is5Columns {
    td,
    th {
      width: calc(100% / 4);
    }
  }

  &.is6Columns {
    td,
    th {
      width: calc(100% / 5);
    }
  }
}

.list tr {
  background-color: $co-white;
  transition: background-color 0.2s ease-in-out;

  & + & {
    margin-top: 10px;
    border-spacing: 10px;
  }

  &.isClickable:hover {
    background-color: $co-greyBgDark;

    td {
      cursor: pointer;
    }
  }

  &.isSelected {
    background-color: $co-cadetblueLight;

    &:hover {
      background-color: $co-cadetblue;
    }
  }
}

.list.hover tr:hover {
  background-color: darken($co-white, 5);
  cursor: pointer;
}

.list td {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(17px), $co: $co-black);
  padding: 12px 10px 11px 17px;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;

  & + & {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 17px;
  }

  &.hasEllipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: ele-cellWidth(ellips);
  }

  &.icon {
    width: 50px;
    padding: 0 10px 0 0;
    button {
      border: none;
      background: transparent;
      cursor: pointer;
      svg {
        fill: $co-greyText;
        color: $co-greyText;
      }
      &:hover svg {
        fill: $co-cadetblue;
        color: $co-cadetblue;
      }
    }
  }

  &.isUnread {
    font-weight: 900;
  }
}

.list td.isBigWithBorder {
  @include font($fs: rem(16px), $fw: $fw-book, $lh: rem(19px), $co: $co-black);
  padding: 18px 23px 16px;
  border: 1px solid $co-greyBorder;
  border-right: 0;

  & + & {
    border-left: 0;
  }

  &:last-child {
    border-right: 1px solid $co-greyBorder;
  }
}

.list th {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(17px), $co: $co-black);
  padding: 17px 10px 16px 17px;
  text-align: left;
  white-space: nowrap;
  background-color: $co-greyBgDark;

  & + & {
    padding-left: 10px;
  }

  &.hasEllipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: ele-cellWidth(ellipsis);
  }
}

.list th.isDark {
  color: $co-white;
  background-color: $co-greyText;
}

.list th.isGreen {
  color: $co-white;
  background-color: $co-cadetblue;
}

.list th.isEqualWidth2 {
  width: 22%;
}

.list th.isEqualWidth3 {
  width: 18%;
}

.list th.isEqualWidth4 {
  width: 14%;
}

.list th.isSubtitle {
  background-color: $co-greyBgDark;
  padding: 18px 20px 16px;
  margin: 10px 0;
  @include font($fs: $fs-medium, $fw: $fw-bold, $lh: rem(19px), $co: $co-black);
}

.list th.isBigger {
  font-size: $fs-medium;
}

@include media-breakpoint-up(lg) {
  .th {
    @include font($fs: $fs-medium, $lh: rem(19px));
    padding: 17px 14px 16px 18px;

    & + & {
      padding-left: 14px;
    }

    &.hasEllipsis {
      max-width: initial;
    }
  }
}
