@use '../../../../styles/modules.scss' as *;
$wrapperPaddingTop: 16px;
$logoHeight: 66px;
$logoMarginBottom: 52px;

.root {
  display: flex;
  min-height: 100vh;
  background-color: $co-white;
  font-family: $font-dft;
  font-weight: $fw-dft;
}
.logoPobles {
  margin-left: calc((490px - 156px) / 2);
  margin-bottom: 50px;
}

.mainImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  width: 40%;
  /*padding: 0 15px;*/
  padding-top: calc((100vh - 400px) / 3);
  background-color: $bg-lightest;
  text-align: center;
  .wrappedImage {
    .alignCenter {
      display: inline-block;
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: $bg-global;
  width: 60%;
  max-width: 800px;
  padding: 20px 83px 0 83px;
}

.footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 420px;
  gap: 20px 60px;

  .wrappedImage + & {
    margin-top: 40px;
  }
}

.logo {
  display: block;
  max-width: 160px;
  max-height: 40px;
  object-fit: contain;
}

