@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 300;
  src: local('Futura Std Light'),
    url('../../fonts/FuturaStdLight.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 300;
  src: local('Futura Std Light Oblique'),
    url('../../fonts/FuturaStdLightOblique.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 400;
  src: local('Futura Std Book'),
    url('../../fonts/FuturaStdBook.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 400;
  src: local('Futura Std Book Oblique'),
    url('../../fonts/FuturaStdBookOblique.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 500;
  src: local('Futura Std Medium'),
    url('../../fonts/FuturaStdMedium.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 500;
  src: local('Futura Std Medium Oblique'),
    url('../../fonts/FuturaStdMediumOblique.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Std Bold'),
    url('../../fonts/FuturaStdBold.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 700;
  src: local('Futura Std Bold Oblique'),
    url('../../fonts/FuturaStdBoldOblique.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 800;
  src: local('Futura Std Extra Bold'),
    url('../../fonts/FuturaStdExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 800;
  src: local('Futura Std Extra Bold Oblique'),
    url('../../fonts/FuturaStdExtraBoldOblique.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 900;
  src: local('Futura Std Heavy'),
    url('../../fonts/FuturaStdHeavy.woff') format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 900;
  src: local('Futura Std Heavy Oblique'),
    url('../../fonts/FuturaStdHeavyOblique.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
